<!-- Start of LiveChat (www.livechatinc.com) code -->

// Hide/Show LiveChat based on country_code
var user_country_code = ($('meta[name="user_country"]').attr('content') !== undefined) ? $('meta[name="user_country"]').attr('content') : 'US';
var user_locale = ($('meta[name="locale"]').attr('content') !== undefined) ? $('meta[name="locale"]').attr('content') : 'en-US';

var hide_from_country_codes = ['GB', 'AU'];
var hide_from_locales = ['es-US', 'en-AU'];

if(hide_from_country_codes.indexOf(user_country_code) == -1 && hide_from_locales.indexOf(user_locale) == -1) {
    window.__lc = window.__lc || {};
    window.__lc.license = 9424905;
    window.__lc.params = [
        { name: "utm_campaign", value: "" },
        { name: "utm_campaign_1st", value: "" },
        { name: "utm_medium", value: "" },
        { name: "utm_medium_1st", value: "" },
        { name: "utm_source", value: "" },
        { name: "utm_source_1st", value: "" },
        { name: "utm_content", value: "" },
        { name: "utm_content_1st", value: "" },
        { name: "gclid", value: "" },
    ];
    (function() {
        var lc = document.createElement('script'); lc.type = 'text/javascript'; lc.async = true;
        lc.src = ('https:' == document.location.protocol ? 'https://' : 'http://') + 'cdn.livechatinc.com/tracking.js';
        var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(lc, s);
    })();
}

<!-- End of LiveChat code -->